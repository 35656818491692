.textscreen {
  position: relative;

  &-wrapper {
    max-width: 800px;
    text-align: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;

    h2 {
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      margin-bottom: 20px;
    }

    &--center {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }

    img {
      max-width: 650px;
      max-height: 275px;
      margin-top: 40px;
    }

    &--side {
      display: grid;
      grid-template-columns: 50% 50%;
      align-items: center;
      height: 100%;

      .textscreen-image {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: fixed;
        bottom: 0;
        right: 0;
        height: calc(100% - 88px);
        width: 50%;

        img {
          margin-top: unset;
          max-width: unset;
          max-height: unset;
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: bottom;
        }

      }
    }
  }

  &-icon {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
}