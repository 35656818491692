@import "shared/styles/variables/colours";
@import "shared/styles/typography";

.multiplechoice {
  position: relative;
  width: 100%;
  height: 100%;

  &-title,
  &-subtitle {
    margin: 0 90px;
    text-align: center;
  }

  &-body {
    height: 100%;
  }

  &-wrapper {
    width: 100%;
    height: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 25px;
    max-width: calc(100% - 20px);
    margin: auto;

    &--topSpace {
      top: 125px;

      :global(.MuiPaper-root) {
        padding: 10px;
      }
    }
  }

  &--side {
    .multiplechoice-body {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      flex-direction: column;
      width: calc(50% - 20px);
      height: 100%;

      h1 {
        margin: 0 24px;
      }

      .multiplechoice-wrapper {
        position: relative;
        height: auto;
        min-height: unset;
        margin: unset;
        bottom: unset
      }
    }

    .multiplechoice-image {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: fixed;
      bottom: 0;
      right: 0;
      height: auto;
      width: 50%;
      height: 100%;

      &>img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: bottom;
      }
    }
  }

  &-container {
    grid-auto-rows: 1fr;
    grid-gap: 20px;
  }

  &__slider-container {
    grid-column: 1 / -1;

    .multiplechoice-option {
      height: calc(100% - 20px);
    }
  }

  &-options {
    grid-column: 1 / -1;
    display: grid;
    grid-auto-rows: 1fr;
    grid-gap: 8px;
    font-size: $baseFontSizeTitle;

    &>div {
      grid-column: span 2;
    }
  }

  &-option {
    all: unset;
    box-sizing: border-box;
    grid-column-start: span 2;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: inline-flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: transparent !important;
    box-shadow: none !important;

    &--withImage {
      justify-content: flex-start;
    }

    &--vertical {
      min-height: 60px;
      justify-content: center;
    }

    &--vertical-withImage {
      display: grid !important;
      grid-template-columns: 50% 50%;
      height: 120px;
      padding: 10px 10px 20px 10px !important;
    }

    &--selected {
      position: relative;
      color: $white !important;

      &__text {
        color: $white !important;
      }
    }

    &--icon {
      position: absolute;
      top: -15px;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, 0);
    }

    &--image {
      width: 100%;
      overflow: hidden;
      margin-top: 5px;
      margin-bottom: 15px;
      border-radius: 4px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        max-height: 30vh;
      }
    }

    &-vertical--image {
      display: flex;
      height: 100%;
      overflow: hidden;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__text {
      margin: auto;
      font-size: 18px;
      text-align: center;
      color: inherit;

      &--small {
        width: 100%;
        font-size: 14px;
      }
    }
  }

  &__illustration-optional {
    grid-column: 3 / 9;
    grid-row: 1 / -1;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 430px);

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  &__illustration {
    grid-column: 3 / 9;
    grid-row: 1 / -1;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 355px);

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  :global {
    .slick-track {
      align-items: start;
    }
  }
}