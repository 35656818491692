@import "shared/styles/variables/colours";
@import "shared/styles/typography";

.select {
  position: relative;
  background-color: transparent !important;

  fieldset {
    border: none;
  }

  .hasIcon {
    :global(.MuiSelect-select) {
      padding-left: 55px;
    }
  }

  &-icon {
    &--before {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translate(0, -50%);
    }

    &--drop:global(.MuiSelect-icon) {
      right: 14px;
      top: inherit;
    }
  }

  :global {
    .MuiOutlinedInput-root {
      font-family: $baseFontFamily;
      min-width: 150px;
      width: 100%;

      .MuiPopover-root {
        width: inherit;
        position: absolute;
        top: 55px;
        left: -3px;
        padding-top: 5px;

        .MuiPaper-root {
          width: 100%;
          max-width: 100%;
          max-height: none;
          top: 0 !important;
          left: 3px !important;
          background-color: $springWood;
          box-shadow: none;
          border: 3px solid $black;
          border-bottom-left-radius: 9px 13px !important;
          border-bottom-right-radius: 12px 7px !important;

          .MuiMenuItem-root {
            font-family: $baseFontFamily;
          }
        }
      }
    }
  }

  :global(.u-border-style-sketch) {
    position: relative;
  }
}