@import 'shared/styles/variables/colours';

.postcodescreen {
  &-title, &-subtitle {
    text-align: center;
  }

  &-pin {
    display: flex;
    justify-content: center;
    margin: 20px 0 10px;
  }

  &-locality {
    text-align: center;
    margin-bottom: 10px;
  }

  &-inputWrapper {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  &-modal {
    :global{
      .MuiPaper-root {
        max-width: 650px;
        overflow: visible;
        background-color: $potPourri;
        
        .MuiDialogContent-root {
          overflow: visible;
          position: relative;
        }
      }
    }
  }
}
