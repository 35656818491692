@import "shared/styles/variables/colours";

.card {
  scrollbar-color: $breakerBay rgba(51, 51, 51, .2);

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(51, 51, 51, .2);
  }

  ::-webkit-scrollbar {
    margin-right: 10px;
    width: 10px;
    background-color: transparent !important;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $breakerBay !important;
  }

  &--centerAlign:global(.MuiCard-root) {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 450px;
    max-width: 80%;
    overflow: visible;
    box-shadow: none;
  }

  &-content {
    &:global(.MuiCardContent-root) {
      padding: 0;
    }

    .title {
      text-align: center;
      color: $white;
      padding: 40px 0;

      h2 {
        margin: 0;
      }
    }

    .children {
      padding: 0 25px 0 50px;
      margin: 40px 25px 40px 0;
    }
  }

  &-action {
    &-button {
      position: relative;
      max-width: 156px;
    }

    &:global(.MuiCardActions-root) {
      padding-bottom: 20px;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      text-align: center;

      &>* {
        flex: 100%;
      }
    }


    &-extraAction {
      margin-top: 13px;
      cursor: pointer;

      button {
        all: unset;
      }
    }
  }
}