@import "shared/styles/variables/colours";
@import "shared/styles/typography";

.piechart {
  position: relative;
  top: 0;
  left: 0;
  min-width: 385px;

  &--three & {
    &__content {
      display: inline-block;
  
      &__label {
        font-size: 28px;
        max-width: 100px;
        text-align: center;
        z-index: 98;
    
        &--0, &--1, &--2 {
          position: absolute;
        }
    
        &--0 {
          top: 117px;
          left: 51px;
        }
    
        &--1 {
          top: 117px;
          left: 230px;
        }
        
        &--2 {
          top: 275px;
          left: 150px;
        }
      }
    
      &__piece {
        &--complete {
          opacity: .1;
        }

        &--0, &--1, &--2 {
          position: absolute;
          cursor: pointer;
        }
    
        &--0 {
          top: 0;
          left: 0;
        }
    
        &--1 {
          top: 0;
          left: 183px;
        }
        
        &--2 {
          top: 193px;
          left: 29px;
        }
      }

      &__tick {
        position: absolute;
        z-index: 99;

        &--0 {
          top: 85px;
          left: -6px;
        }

        &--1 {
          top: 85px;
          left: 333px;
        }

        &--2 {
          top: 360px;
          left: 160px;
        }
      }
    }
  }

  &--four {
    padding: 10px 100px;
    display: inline-grid;
    width: 588px;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0;
  }

  &--four & {
    &__content {
      margin-top: -5px;
      position: relative;

      &__label {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        width: 140px;
        height: 55px;
        text-align: center;
        background-color: $white;
        border: 3px solid $black;
        z-index: 98;

        &--complete {
          border-color: rgba(34, 34, 34, .2);
        }

        &--0 {
          top: 47px;
          left: -80px;
        }

        &--1 {
          top: 47px;
          right: -60px;
        }

        &--2 {
          bottom: 47px;
          left: -80px;
        }

        &--3 {
          bottom: 47px;
          right: -60px;
        }
      }

      &__piece {
        &--complete {
          opacity: .2;
        }
      }

      &__tick {
        position: absolute;
        z-index: 99;

        &--0 {
          top: 30px;
          left: -25px;
        }

        &--1 {
          top: 30px;
          left: 185px
        }

        &--2 {
          top: 80px;
          left: -25px;
        }

        &--3 {
          top: 80px;
          left: 185px;
        }
      }
    }
  }
}