@import "shared/styles/variables/colours";

.grogdiary {
    text-align: center;
    max-width: 900px;
    margin: auto;
    position: relative;
    height: 100%;

    &__list-wrapper {
        width: 100%;

        :global {
            .slick-list {
                margin: 0;
                max-width: 100%;

				.slick-track {
					margin-left: 0;
				}
            }

            .slick-slide {
                padding: 0;

                & > div {
                    width: 100%;
                }
            }
        }
    }

    &__container {
        height: 45vh;
        max-height: calc(100% - 80px);
        overflow-y: auto;
        width: calc(100% + 20px);
    }

    &__title {
        margin-bottom: 30px;
    }

    &__date {
        text-align: left;
        display: flex;
        padding: 10px 0;
        margin-bottom: 5px;
    }

    &__type {
        &-title {
            display: table;
            border-bottom: 2px solid $mineShaft;
            padding-bottom: 5px;
            padding-right: 5px;
        }
    }

    &__action {
        position: absolute;
        bottom: -30px;
        left: 0;
        right: 0;

        &-background {
            &-default {
                background: linear-gradient(to bottom, rgba($springWood, 0), rgba($springWood, 1));
            }

            &-demog {
                background: linear-gradient(to bottom, rgba($potPourri, 0), rgba($potPourri, 1));
            }

            &-initial {
                background: linear-gradient(to bottom, rgba($potPourri, 0), rgba($potPourri, 1));
            }

            &-alcohol {
                background: linear-gradient(to bottom, rgba($bone, 0), rgba($bone, 1));
            }

            &-tobacco {
                background: linear-gradient(to bottom, rgba($clamShell, 0), rgba($clamShell, 1));
            }

            &-cannabis {
                background: linear-gradient(to bottom, rgba($grayNurse, 0), rgba($grayNurse, 1));
            }

            &-meth {
                background: linear-gradient(to bottom, rgba($botticelli, 0), rgba($botticelli, 1));
            }

            &-heroin {
                background: linear-gradient(to bottom, rgba($hampton, 0), rgba($hampton, 1));
            }

            &-opioids {
                background: linear-gradient(to bottom, rgba($athensGray, 0), rgba($athensGray, 1));
            }

            &-benzos {
                background: linear-gradient(to bottom, rgba($graySuit, 0), rgba($graySuit, 1));
            }

            &-other {
                background: linear-gradient(to bottom, rgba($blueHaze, 0), rgba($blueHaze, 1));
            }

            &-harms {
                background: linear-gradient(to bottom, rgba($greenWhite, 0), rgba($greenWhite, 1));
            }

            &-treatment {
                background: linear-gradient(to bottom, rgba($parisWhite, 0), rgba($parisWhite, 1));
            }
        }
    }

    &__list {
        margin-right: 20px;
        &-item {
            display: flex;
            align-items: center;
            border-bottom: 2px solid $mineShaft;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 40px;
            }

            &-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 85%;
                margin-left: 10px;
            }

            &-info {
                width: 15%;
                text-align: left;
            }

            &-drink {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                flex-wrap: nowrap;
                overflow: hidden;
                align-content: flex-end;
                position: relative;

				:global {
					div[class^='Carousel_carousel__'] {
						width: 100%;
					}
				}
            }
        }
    }
}