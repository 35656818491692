@import "shared/styles/variables/colours";
@import "shared/styles/typography";

.button {
  position: relative;
  z-index: 0;

  button {
    font-family: $baseFontFamily;
    font-size: $baseFontSize*1.25;
    font-weight: bold;
    padding: 17px;
    color: $black;

    &:global(.MuiButtonBase-root.Mui-disabled) {
      opacity: .7;
      color: $black;
    }
  }

  &--no-border {
    border: none !important;
  }

  &-variation {
    &-primary {
      button {
        color: $white;

        &:global(.MuiButtonBase-root.Mui-disabled) {
          color: $white;
        }
      }
    }

    &-secondary {
      button {
        color: $black;

        &:not(:disabled):hover {
          background-color: transparent;
        }
  
        &:global(.MuiButtonBase-root.Mui-disabled) {
          color: $black;
        }
      }
    }
  }

  &-width {
    justify-content: center;

    button {
      width: 100%;
    }

    &-xs {
      width: 110px;
    }

    &-s {
      width: 135px;
    }

    &-m {
      width: 160px;
    }

    &-l {
      width: 190px;
    }

    &-xl {
      width: 220px;
    }

    &-xxl {
      width: 280px;
    }

    &-half {
      width: 50%;
    }

    &-full {
      width: 100%;
    }
  }

  &-icon {
    button {
      border: none !important;
      &::before {
        content: none;
      }
    }

    &-top {
      button {
        display: inline-flex;
        flex-wrap: wrap;
        font-size: 14px;
        color: $black;

        & > * {
          flex: 100%;
          height: 32px;
          margin-bottom: 5px;
        }

        svg {
          height: 100%;
        }

        &:hover {
          background-color: transparent;
        }
      }
    }

    &-left {
      button {
        display: flex;
        column-gap: 10px;
      }
    }
  }
}