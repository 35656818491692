.effectspiechartscreen {
  &-titles {
    text-align: center;
  }

  &-chart {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}