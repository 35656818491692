@import "shared/styles/variables/colours";

.profilescreen {
  background-color: $clamShell;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  display: flex;

  &-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 60px;

    &-avatar {
      position: relative;
      width: 220px;
      height: 220px;
      margin-left: auto;
      margin-right: auto;
    }

    &-info {
      position: relative;
      display: inline-block;

      &-content {
        padding: 30px 70px;
        text-align: left;
        display: grid;
        column-gap: 50px;
        row-gap: 10px;
        grid-template-columns: repeat(2, 1fr);

        h5 {
          font-size: 18px;
        }

        p {
          font-size: 16px;
          font-weight: normal;
        }
      }
    }
  }
}