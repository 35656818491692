.tipsscreen {
  &-titles {
    text-align: center;
  }

  &-container {
   height: 100%;
   position: relative;
   margin: 0 80px;
  }
}