@import 'shared/styles/variables/colours';
@import 'shared/styles/variables/keyframes';

.followupbody {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
  text-align: center;

  &-title,
  &-subtitle {
    margin: 0 90px;
    text-align: center;
  }

  &-content {
    padding-top: 50px;
  }

  &-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;

    ul {
      padding-left: 100px;
      display: inline-block;
      text-align: left;
    }
  }

  &-images {
    position: relative;
    width: 235px;
    height: 320px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &-circle {
      all: unset;
      cursor: pointer;
      position: absolute;
      max-width: 70px;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    &-selected {
      border: 3px $white solid;
      border-radius: 50%;
    }

    &-wiggle {
      animation-name: wobble-horizontal;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }

    &-brain {
      top: -30px;
      left: calc((100% - 75px) / 2);
    }

    &-mood {
      top: 25px;
      left: 0;
    }

    &-liver {
      top: 100px;
      left: 0;
    }

    &-kidney {
      top: 175px;
      left: 0;
    }

    &-fertility {
      top: 250px;
      left: 0;
    }

    &-teeth {
      top: 25px;
      right: 0;
    }

    &-heart {
      top: 100px;
      right: 0;
    }

    &-stomach {
      top: 175px;
      right: 0;
    }

    &-limbs {
      top: 250px;
      right: 0;
    }
  }
}