@import 'shared/styles/variables/colours';

.forgotpin {
  &-container {
    background-color: $clamShell;
    width: 100%;
    height: 100%;

    :global {
      .MuiCardContent-root {
        padding: 0;
      }
      .MuiPaper-root {
        background: none;
      }
    }
  }

  &-text {
    text-align: center;
    margin: 25px 0;
  }
}
