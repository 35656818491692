.circlechoice {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
  text-align: center;

  &-title,
  &-subtitle {
    margin: 0 90px;
    text-align: center;
  }

  &-content {
    position: absolute;
    width: 100%;
    height: calc(100% - 100px);
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 25px;
    max-width: calc(100% - 20px);
    margin: 20px auto auto auto;
  }

  &-optionText {
    height: 42px;
    margin-bottom: 15px;
  }

  &-wrapper {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  &-option {
    position: relative;
    width: 133px !important;
    height: 133px !important;

    &--dimmer {
      opacity: .5;
    }
  }

  &-slider {
    width: 100%;
  }
}
