@import 'shared/styles/typography';

.timeclock {
  min-width: 65px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  & > * {
    flex: 1;
  }

  p {
    font-weight: bold;
    margin: 0;
    padding-top: 5px;
  }
}