@import "shared/styles/variables/colours";
@import "shared/styles/typography";

.text {
  &-title {
    margin-bottom: 9px;
  }

  &-field {
    width: 100%;
  
    input {
      padding: 10px;
      font-family: $baseFontFamily;
    }

    fieldset {
      border: none !important;
    }
  }
}
