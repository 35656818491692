.usingamountscreen {
  &-titles {
    text-align: center;
  }

  &-container {
   height: 100%;
   position: relative;
  }

  &-amount {
    max-height: 50%;
  }

  :global { 
    .slick-slider {
      max-height: 600px;
      padding: 0 20px;
    }

    .slick-slide img {
      margin: auto;
    }
  }
}