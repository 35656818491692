.border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }

  &-disabled {
    opacity: .7;
  }

  &-transform {
    transform: scale(-1, -1);
  }

  &-dropShadow {
    z-index: -1;

    svg {
      filter: drop-shadow(0px 6px 0px rgba(51, 51, 51, 0.2));
      -webkit-filter: drop-shadow(0px 6px 0px rgba(51, 51, 51, 0.2));
    }

    &.border-transform {
      will-change: transform;

      svg {
        filter: drop-shadow(0px -6px 0px rgba(51, 51, 51, 0.2));
        -webkit-filter: drop-shadow(0px -6px 0px rgba(51, 51, 51, 0.2));
      }
    }
  }

  &-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    filter: none !important;
    z-index: 1;
  }
}
