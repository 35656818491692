.standarddrinksscreen {
  position: relative;

  &-titles {
    text-align: center;
    margin-bottom: 30px;
  }

  &-amount {
    margin: 0 50px;
    display: grid;
    column-gap: 30px;
    align-items: center;
    justify-items: center;
    overflow-y: scroll;

    img {
      max-width: 100%;
    }
  }
}