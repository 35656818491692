.form {
  text-align: center;

  &-alert {
    &--hidden {
      visibility: hidden;
    }
  }

  &-options {
    margin-left: auto;
    margin-right: auto;
    max-width: 650px;
    margin-top: 50px;
  }


  &-option {
    margin-top: 30px;

    &--first {
      margin-top: 10px;
    }
  }
}