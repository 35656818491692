.footer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 70px;
  bottom: 15px;

  .icons {
    position: absolute;
    bottom: 0;
    left: 0;
    display: inline-flex;
    align-items: center;
  }

  .buttons {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
}