@import 'typography';
@import './variables/colours';
@import './variables/mixins';

$containerMaxWidth: 1366px;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  @media only screen and (-ms-high-contrast: none) {
    height: 100vh;
  }
}

body {
  box-sizing: border-box;
  overflow: hidden;
  /* Required to disbale zoom on iOS */
  touch-action: pan-x pan-y;

  @media only screen and (-ms-high-contrast: none) {
    height: 100%;
  }
}

html,
body {
  font-family: $baseFontFamily;
  font-weight: $baseFontWeight;
  color: $mineShaft;
  margin: 0;
  height: 100%;
}

#root,
.drug-app {
  width: 100%;
  height: 100%;
}

.drug-app {
  position: relative;
}