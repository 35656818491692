@font-face {
  font-family: Manrope;
  src: url("../fonts/Manrope-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url("../fonts/Manrope-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Manrope;
  src: url("../fonts/Manrope-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url("../fonts/Manrope-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Manrope;
  src: url("../fonts/Manrope-Medium.ttf") format("truetype");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url("../fonts/Manrope-Medium.ttf") format("truetype");
  font-weight: lighter;
  font-style: italic;
}