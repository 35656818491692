@import "shared/styles/variables/colours";
@import "shared/styles/typography";

.addmultidate {
  text-align: center;
  max-width: 1000px;
  margin: auto;

  &__title {
    margin-bottom: 30px;
  }

  &__subtitle {
    font-size: 18px;
    margin-bottom: 20px;
  }

  &__slide {
    min-height: 225px;
    display: flex !important;
    flex-direction: column;
    overflow: hidden;
    padding: 3px;
    background-color: transparent !important;

    &--date {
      box-shadow: none !important;
    }

    &-title {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }

  &__disabled {
    opacity: .3;
  }

  &__adddate {
    position: relative;
    top: 39.5px;
    justify-content: center;
    align-items: center;
    height: calc(100% - 39.5px) !important;
    cursor: pointer;
  }

  &__month {
    background-color: transparent !important;
    font-size: 28px;
    color: $white;
    padding: 20px;
  }

  &__day {
    font-size: 96px;
    font-weight: bold;
    line-height: 130px;
    padding: 6px;
  }

  &__icon {
    margin-bottom: 10px;
  }

  :global {
    .slick-track {
      width: 100% !important;

      .slick-slide {
        min-width: 200px;
      }

      .slick-arrow {
        margin-top: 36px;
      }
    }
  }
}