@import 'shared/styles/variables/colours';
@import "shared/styles/typography";

.autocomplete {
  &-option {
    padding: 6px 16px;
    text-align: left;
    cursor: pointer;
    
    &:focus {
      background-color: aqua !important;
    }
  }

  :global {
    .MuiInputBase-input {
      font-family: $baseFontFamily;
      font-weight: $baseFontWeight;
    }
  
    em {
      font-style: normal;
    }

    .MuiAutocomplete-popper.MuiAutocomplete-popperDisablePortal {
      top: -2px !important;
      border: 3px solid $black;
      border-top-left-radius: 4px !important;
      border-top-right-radius: 4px !important;
      border-bottom-left-radius: 9px 13px !important;
      border-bottom-right-radius: 12px 7px !important;
    }

    .MuiAutocomplete-paper {
      font-family: $baseFontFamily;
      background-color: $springWood !important;
    }

    .MuiAutocomplete-root{
      .MuiOutlinedInput-input {
        padding: 10.5px 6px;
        &::placeholder {
          color: $black;
          opacity: 1;
        }
      }
    }
  }
}
