@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes wobble-horizontal {
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  66.6% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}