@import "shared/styles/typography";
@import "shared/styles/variables/colours";

.dynamicslider {
  position: relative;
  text-align: center;
  max-width: 80%;
  height: 100%;
  margin: auto;

  &-staticImage {
    img {
      max-width: 100%;
      max-height: 300px;
    }
  }

  &-horizontal {
    max-width: 1000px;
    margin: auto;
    margin-top: 30px;

    &-wrapper {
      display: flex;

      img {
        max-width: 80px;
      }
    }

    &-item {
      transition: all ease-in .3s;

      &:nth-child(5n) {
        margin-right: 30px;
      }

      &:last-child {
        margin-right: 0;
      }

      &--half:last-child {
        position: relative;
        display: inline-block;
        .opacityDiv {
          height: 100%;
          width: 50%;
          position: absolute;
          top: 0;
          right: 0;
          opacity: 0.8;
          z-index: 99;
        }
      }
    }
  }

  &-innerWrapper {
    height: 100%;
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: center;
    position: absolute;
    bottom: 10px;

    :global(.MuiSlider-root) {
      max-width: 532px;
    }
  }

  .sliderAlign {
    &--middle {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }

    &--inline {
      display: inline-grid;
      grid-template-columns: auto auto;
      column-gap: 25px;
      align-items: flex-start;

      :global(.image-space-holder) {
        margin-top: 0;
      }

      .slider {
        margin-top: 0 !important;
      }

      &>* {
        flex: 1 auto;
      }
    }

    .slider {
      margin-top: 55px;

      :global {
        .MuiSlider-track {
          border: none;
        }

        .MuiSlider-mark {
          display: none;
        }

        .MuiSlider-rail {
          color: rgba(51, 51, 51, 0.2);
        }

        .MuiSlider-thumb {
          height: 47px;
          width: 47px;
          border: none;
          box-shadow: 0px 6px 0px rgba(51, 51, 51, 0.2);

          &::before {
            display: none;
          }
        }

        .MuiSlider-valueLabel {
          font-family: $baseFontFamily;
          font-size: 18px;
          padding-top: 0;
          padding-bottom: 0;
          height: 40px;
          min-width: 120px;
          top: -20px;
          border-top-left-radius: 8px 9px;
          border-top-right-radius: 12px 8px;
          border-bottom-left-radius: 9px 13px;
          border-bottom-right-radius: 12px 7px;
          will-change: transform;
        }
      }

      &--round {
        :global {
          .MuiSlider-valueLabel {
            font-size: 28px;
            padding: 0;
            width: 57px;
            height: 57px;
            min-width: 0;
            top: -10px;
            border-radius: 50% 50% 50% 0;
            transform-origin: bottom left;
            transform: translate(50%, -100%) rotate(-45deg) scale(0);

            &::before {
              display: none;
            }

            &.MuiSlider-valueLabelOpen {
              transform: translate(50%, -100%) rotate(-45deg) scale(1);
            }

            & > * {
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
}