@import "shared/styles/variables/colours";
@import "shared/styles/typography";

.projectselectorscreen {
  background-color: $clamShell;
  width: 100%;
  height: 100%;

  &-select {
    display: grid;
    row-gap: 24px;

    
    :global(.MuiInputBase-input), li {
      font-weight: $baseFontWeight;
    }
  }

  :global(.MuiCard-root) {
    background-color: transparent;
  }
}