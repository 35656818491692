@import 'shared/styles/variables/colours';
.enterparticipantsslkscreen {
  background-color: $graySuit;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  display: flex;

  &-content {
    margin-top: 10%;
    margin-bottom: 5%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &-inputcontainer {
      width: 80%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-input {
        width: 35%;
      }

      &-errortext {
        color: red;
        margin-top: 10px;
      }
    }

    &-foundedtextcontainer {
      margin-top: 5%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      &-title {
        margin-bottom: 20px;
      }
    }
  }
}