@import "shared/styles/variables/colours";
@import "shared/styles/typography";

.calendarmultiselect {
  text-align: center;

  &__calendar-icon {
    position: absolute;
    right: -10px;
    top: -5px;
  }

  &__title {
    margin: 0;
    margin-bottom: 10px;
  }

  :global(.react-calendar__month-view__days) {
    .calendarmultiselect__today:global(.react-calendar__tile--now) {
      color: $mineShaft !important;
      opacity: 1 !important;

      &:disabled {
        background-color: transparent !important;
      }
    }
  }

  &__selected-date {
    opacity: 1 !important;
    color: $mineShaft !important;
    pointer-events: none;
  }

  &__button {
    border: none;
    background: none;
    cursor: pointer;

    &--morethan12 {
      max-width: 150px;
      margin-left: auto;
      z-index: 9999;

      &--flat {
        max-width: 250px;
      }
    }

    &:global(.initial-month) {
      animation-name: arrowleft;
      animation-duration: 700ms;
      animation-timing-function: cubic-bezier(0.45, 0, 0.55, 1); // easeInOutQuad
      animation-fill-mode: both;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  }

  &__calendar-title {
    margin: 10px 0;
  }

  :global {
    .react-calendar {
      max-width: 760px;
      margin: auto;

      &__tile {
        position: relative;
        font-family: $baseFontFamily !important;
        background-color: $white !important;
        border: 3px solid $black;
        border-radius: 5px;
        font-weight: bold;
        padding: 3px;
        font-size: 14px;
        min-height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: initial !important;
        box-shadow: 0px 6px 0px rgba(51, 51, 51, 0.2);

        &:disabled {
          opacity: 0.3;
          background: transparent;
        }

        label {
          font-size: 10px;
          font-weight: bold;
        }
      }

      &__month-view__weekdays {
        display: grid !important;
        grid-template-columns: repeat(7, 1fr) !important;
        grid-gap: 10px 20px !important;
        text-align: center;
        margin-bottom: 10px;
        font-weight: bold;

        &__weekday>* {
          text-decoration: none !important;
        }
      }

      &__month-view__days {
        display: grid !important;
        grid-template-columns: repeat(14, 1fr) !important;
        grid-gap: 10px 20px !important;

        &__day--neighboringMonth {
          opacity: .3;
          pointer-events: none;
        }
      }
    }
  }
}

@keyframes arrowleft {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-4%);
  }
}