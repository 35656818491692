.container {
    &__mask {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        mask-size: contain;
        transition: clip-path ease .3s;
        opacity: .8;
        mask-position: center;
        mask-repeat: no-repeat;
    }

    &__image {
        display: block;
        margin: auto;
    }

    &__wrapper {
        position: relative;
    }
}