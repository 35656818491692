@import 'shared/styles/typography';
@import 'shared/styles/variables/colours';

.multiplechoiceimagescreen {
  position: relative;

  div[class^="MultipleChoice_multiplechoice-wrapper__"] {
    position: unset;
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    h2 {
      text-align: center;
    }
  }

  &-subtitle {
    text-align: center;
    margin-bottom: 30px;

    &--hasOptions {
      padding-top: 0;
    }
  }

  &-choices {
    position: absolute;
    bottom: 20px;
    width: 100%;
    height: calc(100% - 170px);
    text-align: center;
  }

  &-buttons {
    margin-left: 15px;
  }

  &-button {
    position: relative;
    z-index: 10;

    &:global(.MuiToggleButton-root) {
      min-width: 115px;
      min-height: 55px;
      color: $mineShaft;
      text-transform: none;
      font-weight: $baseFontWeight;
      font-size: $baseFontSizeTitle;
      border: none;
    }

    &:global(.MuiToggleButton-root.Mui-selected) {
      color: $white;
    }
  }
}