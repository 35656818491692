@import "shared/styles/typography";

.contactsscreen {
  &-titles {
    text-align: center;
  }

  &-container {
   height: 100%;
   position: relative;
   margin: 0 100px;
   display: flex;
   align-items: center;
   justify-content: center;
   column-gap: 120px;

   &-column {
    text-align: center;
    max-width: 400px;

    img {
      width: auto;
      height: 65px;
      margin-bottom: 30px;
    }

    &-contact {
      margin-top: 30px;
    }
   }

   p {
    font-size: $baseFontSizeTitle;
    font-weight: $baseFontWeightLight;
   }
  }
}