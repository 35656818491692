.feedbackscreen {
  text-align: center;

  &-wrapper {
    position: relative;
    height: 100%;
  }

  &-feedbackIcons {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
}