.sectionprogress {
  &-titles {
    max-width: 600px;
    margin-bottom: 70px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  &-options {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 20px;
    align-items: center;
    justify-content: center;
  }

  &-option {
    grid-column-start: span 2;
    position: relative;
    cursor: pointer;
    z-index: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--completed {
      opacity: .5;
      cursor: default;
    }

    &--dimmed {
      opacity: .5;
    }

    &-card {
      font-weight: bold;
      text-align: center;
      background-color: transparent !important;
      box-shadow: none !important;
      padding: 25px 15px 15px;
    }

    &-tickIcon {
      position: absolute;
      top: -15px;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, 0);
    }

    &-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      & > * {
        flex: 100%;
      }
    }

    &-icon {
      img {
        width: 55px;
        height: auto;
      }
    }
  }
}