@import "shared/styles/typography";

.sliderWrapper {
  text-align: center;
  height: 100%;

  h1 {
    margin: 0 90px;
  }

  .slider {
    &__inner-wrapper {
      height: 100%;
      display: flex;
      align-items: flex-end;
      width: 100%;
      justify-content: center;

    }
  }

  .sliderAlign {
    &--middle {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }

    .slider {
      margin-top: 55px;
      max-width: 532px;

      :global {
        .MuiSlider-track {
          border: none;
        }

        .MuiSlider-mark {
          display: none;
        }

        .MuiSlider-rail {
          color: rgba(51, 51, 51, 0.2);
        }

        .MuiSlider-thumb {
          height: 47px;
          width: 47px;
          border: none;
          box-shadow: 0px 6px 0px rgba(51, 51, 51, 0.2);

          &::before {
            display: none;
          }
        }

        .MuiSlider-valueLabel {
          font-family: $baseFontFamily;
          font-size: 18px;
          padding-top: 0;
          padding-bottom: 0;
          height: 40px;
          min-width: 120px;
          top: -20px;
          border-top-left-radius: 8px 9px;
          border-top-right-radius: 12px 8px;
          border-bottom-left-radius: 9px 13px;
          border-bottom-right-radius: 12px 7px;
          will-change: transform;
        }
      }

      &--round {
        :global {
          .MuiSlider-valueLabel {
            font-size: 28px;
            padding: 0;
            width: 57px;
            height: 57px;
            min-width: 0;
            top: -10px;
            border-radius: 50% 50% 50% 0;
            transform-origin: bottom left;
            transform: translate(50%, -100%) rotate(-45deg) scale(0);

            &::before {
              display: none;
            }

            &.MuiSlider-valueLabelOpen {
              transform: translate(50%, -100%) rotate(-45deg) scale(1);
            }

            &>* {
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
}