@import 'shared/styles/variables/colours';

.postcode {
  &__numbers {
    display: grid;
    grid-row-gap: 10px;
    grid-column-gap: 20px;
    justify-content: center;
    font-weight: bold;
    font-size: 28px;
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: transparent !important;

    & > div {
      margin-top: 6px !important;
      color: $mineShaft !important;
    }
  }

  &__del {
    grid-column: span 2;
    display: flex;
    align-items: center;

    button {
      height: 55px;
    }
  }

  &__idk {
    grid-column: span 3;
  }
}
