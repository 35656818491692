.product {
    &-wrapper {
        position: relative;
    }

    &-img {
        position: absolute;
        left: 0;
        bottom: 0;
        object-fit: contain;
        object-position: bottom;
        transition: all ease .3s;
        max-height: 200px;
    }

    &-background {
        opacity: 0.2;
        display: block;
        width: 100%;
        object-fit: contain;
        max-height: 200px;
    }
}