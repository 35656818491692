.fade {
    &-enter {
        opacity: 0;
    }

    &-enter-active {
        opacity: 1;
        transition: opacity 300ms ease-in;
    }

    &-exit {
        opacity: 1;
    }

    &-exit-active {
        opacity: 0;
        transition: opacity 300ms ease-in;
    }
}