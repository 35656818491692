.calendardate {
  text-align: center;

  &-subtitle {
    font-weight: 400;
  }

  &-wrapper {
    position: absolute;
    top: 90px;
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    justify-content: center;
    column-gap: 5%;
  }

  &-month {
    :global {
      .MuiBox-root {
        grid-row-gap: 10px;

        .MuiPaper-root {
          height: auto;
          font-weight: bold;
          padding: 10px 35px;
        }
      }
    }
  }

  &-date {
    :global {
      .MuiBox-root {
        grid-gap: 10px;

        .MuiPaper-root {
          height: auto;
          font-weight: bold;
          padding: 15px;
        }
      }
    }
  }
}