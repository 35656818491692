@import "shared/styles/variables/colours";

.pinscreen {
  background-color: $clamShell;
  width: 100%;
  height: 100%;

  &-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);

    :global(.MuiCard-root) {
      position: relative;
      top: unset;
      left: unset;
      margin-right: 0;
      transform: none;
      width: 100%;
      max-width: 100%;
      background-color: transparent;
    }
  }

  &-demoButtonWrapper {
    margin-top: 60px;
  }

  &-demoButton {
    width: 276px;

    button {
      padding: 15px 50px;
    }
  }

  &-alert {
    margin-bottom: 20px;
  }

  &-info {
    padding: 0 30px;
  }

  &-text {
    margin-bottom: 25px;
  }
}