@import "shared/styles/typography";

.grog-slider {

  &__wrapper {
    text-align: center;
  }


  &__dynamic {
    &--vertical {
      margin-top: 30px;
      position: relative;

      &-mask {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        mask-size: contain;
        transition: all ease .3s;
        opacity: .8;

      }

      &-image {
        max-height: 450px
      }

      &-wrapper {
        position: relative;
      }
    }

    &--horizontal {
      max-width: 1000px;
      margin: auto;
      margin-top: 30px;

      &-wrapper {
        display: flex;
        flex-flow: row wrap;
        min-height: 300px;
        align-items: center;
      }

      &-item {
        max-height: 150px;
        flex: 0 1 calc(10% - 4px);
        transition: opacity ease 0.5s;

        img {
          max-height: 150px;
        }


        &:nth-child(5n) {
          margin-right: 40px;
        }

        &:nth-child(10n) {
          margin-right: 0px;
        }

        &:last-child {
          margin-bottom: 0;
        }

      }

    }

    &-button {
      margin-left: 60px;
      margin-right: -220px;

      button {
        padding: 18px;
      }

    }

    &-icon {
      position: absolute;
      top: -18px;

      &--fizzy {
        transform: rotate(45deg);
      }
    }
  }

  &__inner-wrapper {
    height: calc(100% - 15px);
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: center;
  }
}

.sliderAlign {
  &--middle {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  &--inline {
    display: inline-grid;
    grid-template-columns: auto auto;
    column-gap: 25px;
    align-items: flex-start;

    :global(.image-space-holder) {
      margin-top: 0;
    }

    .slider {
      margin-top: 0 !important;
    }

    &>* {
      flex: 1 auto;
    }
  }

  .slider {
    margin-top: 55px;
    max-width: 532px;

    :global {
      .MuiSlider-track {
        border: none;
      }

      .MuiSlider-mark {
        display: none;
      }

      .MuiSlider-rail {
        color: rgba(51, 51, 51, 0.2);
      }

      .MuiSlider-thumb {
        height: 47px;
        width: 47px;
        border: none;
        box-shadow: 0px 6px 0px rgba(51, 51, 51, 0.2);

        &::before {
          display: none;
        }
      }

      .MuiSlider-valueLabel {
        font-family: $baseFontFamily;
        font-size: 18px;
        padding-top: 0;
        padding-bottom: 0;
        height: 40px;
        min-width: 120px;
        top: -20px;
        border-top-left-radius: 8px 9px;
        border-top-right-radius: 12px 8px;
        border-bottom-left-radius: 9px 13px;
        border-bottom-right-radius: 12px 7px;
        will-change: transform;
      }
    }

    &--round {
      :global {
        .MuiSlider-valueLabel {
          font-size: 28px;
          padding: 0;
          width: 57px;
          height: 57px;
          min-width: 0;
          top: -10px;
          border-radius: 50% 50% 50% 0;
          transform-origin: bottom left;
          transform: translate(50%, -100%) rotate(-45deg) scale(0);

          &::before {
            display: none;
          }

          &.MuiSlider-valueLabelOpen {
            transform: translate(50%, -100%) rotate(-45deg) scale(1);
          }

          &>* {
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}