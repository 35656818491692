.textboxscreen {
  &-titles {
    text-align: center;
  }

  &-container {
    height: calc(100% - 80px);
    display: flex;
    position: relative;
    margin-left: 175px;
    margin-right: 80px;

    img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%)
    }
  }

  &-textbox {
    position: absolute;
    top: 50%;
    left: -140px;
    transform: translate(0, -50%);
    padding: 35px;
    z-index: 10;
    
    ul {
      max-width: 230px;
      padding-left: 20px;

      li {
        margin-bottom: 10px;
      }
    }
  }
}