/* Colour Palette */
$athensGray: #DFE2E8;
$black: #000000;
$blueHaze: #D9CCE0;
$bone: #E2C8B6;
$botticelli: #C2CFDF;
$breakerBay: #55968C;
$capePalliser: #A1664C;
$clamShell: #D9C2B7;
$contessa: #C57861;
$grayNurse: #DEE3E0;
$graySuit: #BEB9CD;
$greenSmoke: #8FA26F;
$greenWhite: #EEF1E9;
$hampton: #E5CFAB;
$mineShaft: #222222;
$parisWhite: #CCE0DD;
$potPourri: #F2E6DE;
$springWood: #F7F3F0;
$swissCoffee: #E2D8D7;
$tundora: #404040;
$white: #FFFFFF;

$landingBgColor: $capePalliser;