.alcoholriskscreen {
  position: relative;

  &-container {
    max-width: 100%;
    margin: 0;
    padding: 20px 80px;
  }

  &-textWrapper {
    padding: 0 !important;

    br {
      display: block;
      content: "";
      margin-top: 30px;
    }
  }

  &-image {
    max-width: 100%;
    width: 422px;
    display: block;
    margin: auto;
  }
}