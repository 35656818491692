@import "shared/styles/variables/colours";
@import "shared/styles/typography";

.carousel {
  height: 100%;

  &__slides {
    margin: auto;
    display: flex !important;
    align-items: center;
  }

  :global {
    .slick-slide {
      height: auto;
      padding: 10px;
      transition: all ease 0.3s;

      &:not(.slick-active) {
        opacity: 0.3;
        pointer-events: none;
      }

      & > div {
        height: 100%;

        & > div {
          position: relative;
          height: 100%;
        }
      }
    }

    .slick-disabled {
      visibility: hidden;
    }

    .slick-list {
      width: 85vw;
      max-width: 90%;
      overflow: visible;
      margin: auto;
    }

    .slick-track {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: stretch;
      padding: 24px 0;
    }

    .slick-arrow {
      cursor: pointer;
      z-index: 999;
    }
  }
}