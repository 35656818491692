@import "shared/styles/variables/colours";
@import "shared/styles/typography";

.pin {
  border: none;

  &-title {
    margin-bottom: 8px;
  }

  &-wrapper {
    display: inline-flex;
    column-gap: 15px;
    justify-content: space-between;
  }

  &-digit {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      font-family: $baseFontFamily;
      padding: 0;
      text-align: center;
      font-size: 40px;
      font-weight: bold;

      &:disabled {
        -webkit-text-fill-color: $black;
        color: $black;
        opacity: 1;
      }
    }

    fieldset {
      border: none;
    }
  }
}