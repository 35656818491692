@import "shared/styles/variables/colours";
@import "shared/styles/global";

.screen {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;

  &-default {
    background-color: $springWood;
  }

  &-demog {
    background-color: $potPourri;
  }

  &-initial {
    background-color: $swissCoffee;
  }
  
  &-alcohol {
    background-color: $bone;
  }

  &-tobacco {
    background-color: $clamShell;
  }

  &-cannabis {
    background-color: $grayNurse;
  }

  &-meth {
    background-color: $botticelli;
  }

  &-heroin {
    background-color: $hampton;
  }

  &-opioids {
    background-color: $athensGray;
  }

  &-benzos {
    background-color: $graySuit;
  }

  &-other {
    background-color: $blueHaze;
  }

  &-harms {
    background-color: $greenWhite;
  }

  &-treatment {
    background-color: $parisWhite;
  }

  .container {
    position: relative;
    width: 100%;
    height: calc(100vh - 161px);
    max-width: $containerMaxWidth;
    margin: auto;
    padding: 50px 0;
  }
}
