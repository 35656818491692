@import "shared/styles/typography";
@import "shared/styles/variables/colours";

.drinkingcircleslider {
  position: relative;
  text-align: center;
  max-width: 80%;
  height: 100%;
  margin: auto;

  &-images {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;

    img {
      max-width: 100%;
      max-height: 200px;
    }
  }

  &-innerWrapper {
    height: 100%;
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: center;

    :global(.MuiSlider-root) {
      max-width: 532px;
    }
  }

  .sliderAlign {
    &--middle {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }

    &--inline {
      display: inline-grid;
      grid-template-columns: auto auto;
      column-gap: 25px;
      align-items: flex-start;

      :global(.image-space-holder) {
        margin-top: 0;
      }

      .slider {
        margin-top: 0 !important;
      }

      &>* {
        flex: 1 auto;
      }
    }

    .slider {
      margin-top: 30px;

      :global {
        .MuiSlider-track {
          border: none;
        }

        .MuiSlider-mark {
          display: none;
        }

        .MuiSlider-rail {
          color: rgba(51, 51, 51, 0.2);
        }

        .MuiSlider-thumb {
          height: 47px;
          width: 47px;
          border: none;
          box-shadow: 0px 6px 0px rgba(51, 51, 51, 0.2);

          &::before {
            display: none;
          }
        }

        .MuiSlider-valueLabel {
          font-family: $baseFontFamily;
          font-size: 18px;
          padding-top: 0;
          padding-bottom: 0;
          height: 40px;
          min-width: 120px;
          top: -20px;
          border-top-left-radius: 8px 9px;
          border-top-right-radius: 12px 8px;
          border-bottom-left-radius: 9px 13px;
          border-bottom-right-radius: 12px 7px;
          will-change: transform;
        }
      }
    }
  }
}